import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import Persons from "../../assets/images/persons.png";
import HandShake from "../../assets/images/handshake.png";
import "./acctivation.css";

export default function Acctivation() {
  const [request, setRequest] = useState({
    loading: false,
    msg: "",
    success: false,
    data: null,
    forceUpdate: false,
  });

  const handleAcctivation = async () => {
    setRequest(Object.assign({}, request, { loading: true }));
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const { token } = Object.fromEntries(urlSearchParams.entries());
      console.log({ urlSearchParams, token });
      let response = await fetch(
        "https://nqtestingapp.herokuapp.com/api/customer/activate/account",
        {
          method: "POST",
          body: JSON.stringify({ token }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (data.success) {
        setRequest(
          Object.assign(request, {
            loading: false,
            msg: data.msg,
            success: true,
            data: data.doc,
          })
        );
      } else {
        setRequest(
          Object.assign(request, {
            loading: false,
            success: false,
            msg: data.msg,
          })
        );
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      setRequest(
        Object.assign(request, {
          loading: false,
          msg: "Some Internal Server error, please try again later!",
        })
      );
    }
  };

  // useEffect(() => {
  //   setRequest(Object.assign(request, { forceUpdate: true }));
  // }, [request]);

  return (
    <div class="container login-container">
      <div class="row">
        <div class="col-md-6 login-form-1">
          <img src={Logo} class="img-fluid mb-5 logo" />
          <img src={Persons} class="img-fluid" />
        </div>
        <div class="col-md-6 login-form-2 text-center">
          <h3>Welcome !</h3>
          <img src={HandShake} class="img-fluid handshake" />
          <p class="para">
            We're excited to have you get started. First you need to confirm
            your account. Just press the button below.
          </p>

          {request.msg !== "" && (
            <div className={request.success ? "message success" : "message"}>
              <button
                onClick={() =>
                  setRequest(Object.assign({}, request, { msg: "" }))
                }
                className="btn noq_btn_close"
              >
                x
              </button>
              <p
                style={{
                  margin: 0,
                  color: request.success ? "#eee" : "#e21414",
                }}
              >
                * {request.msg}
              </p>
            </div>
          )}

          <button
            onClick={() => handleAcctivation()}
            class="btnSubmit mt-4"
            value="Login"
          >
            Confirm Account
          </button>
        </div>
      </div>
    </div>
  );
}
