// Note: Routes component...!

import React from "react";
// import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Note: Importing reuseabale components...!
import MainHome from "./components/parent/index";
import Acctivation from "./pages/Activation/Acctivation";
import Forget from "./pages/Forget";

const AppRoutes = () => {
  // Note: One way for maintain user session using cookies redux...!
  // let getUser = useSelector(({ users }) => { return users.authenticatedUser });
  // console.log(getUser);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/account/verify" component={Acctivation} />
          <Route path="/reset" component={Forget} />
          <Route exact path="/" component={MainHome} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default AppRoutes;
