import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import Persons from "../../assets/images/persons.png";
import "./forget.css";

export default function Forget() {
  const [request, setRequest] = useState({
    loading: false,
    msg: "",
    success: false,
    data: null,
    forceUpdate: false,
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleAcctivation = async (e) => {
    e.preventDefault();
    setRequest(Object.assign({}, request, { loading: true }));
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const { token } = Object.fromEntries(urlSearchParams.entries());
      console.log({ urlSearchParams, token });
      if (password !== confirmPassword) {
        setRequest(
          Object.assign({}, request, {
            loading: false,
            msg: "Password does not match with Confirm Password!",
          })
        );
        return;
      }
      if (!token) {
        setRequest(
          Object.assign({}, request, {
            loading: false,
            msg: "Link Expired, Please try again!",
          })
        );
        return;
      }

      let response = await fetch(
        "https://nqtestingapp.herokuapp.com/api/customer/forget/password",
        // "https://DESKTOP-ED504NI:9999/api/customer/forget/password",
        {
          method: "POST",
          body: JSON.stringify({ token, password, confirmPassword }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();

      if (data.success) {
        setRequest(
          Object.assign({}, request, {
            loading: false,
            msg: data.msg,
            success: true,
            data: data.doc,
          })
        );
      } else {
        setRequest(
          Object.assign({}, request, {
            loading: false,
            success: false,
            msg: data.msg,
          })
        );
        console.log(data);
      }
    } catch (error) {
      console.log(error);
      setRequest(
        Object.assign({}, request, {
          loading: false,
          success: false,
          msg: "Some Internal Server error, please try again later!",
        })
      );
    }
  };

  // useEffect(() => {
  //   setRequest(Object.assign(request, { forceUpdate: true }));
  // }, [request]);

  return (
    <div class="container login-container">
      <div class="row">
        <div class="col-md-6 login-form-1">
          <img src={Logo} alt="Logo" class="img-fluid mb-5 logo" />
          <img src={Persons} alt="Persons" class="img-fluid" />
        </div>
        <div class="col-md-6 login-form-2 text-center">
          <h3>Reset Password !</h3>
          <form onSubmit={handleAcctivation}>
            {request.msg !== "" && (
              <div className={request.success ? "message success" : "message"}>
                <button
                  onClick={() =>
                    setRequest(Object.assign({}, request, { msg: "" }))
                  }
                  className="btn noq_btn_close"
                >
                  x
                </button>
                <p
                  style={{
                    margin: 0,
                    marginRight: 40,
                    color: request.success ? "#eee" : "#e21414",
                  }}
                >
                  * {request.msg}
                </p>
              </div>
            )}
            <div class="form-group">
              <input
                type="text"
                name="passowrd"
                class="form-control"
                placeholder="New Password *"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div class="form-group">
              <input
                name="confirm password"
                class="form-control"
                placeholder="Confirm Password *"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div class="form-group">
              <input type="submit" class="btnSubmit" value="Reset" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
